export default new class {
    async getMasterPurchasePlan(filters, xlsx) {
        return (await axios.get('cockpit/purchase-plan/master', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null),
            responseType: xlsx ? 'blob' : undefined,
        })).data;
    }

    async getProductPurchasePlan(filters, xlsx) {
        const extraFilters = {};

        if (filters.has_7f_forecasts !== null) {
            extraFilters.has_7f_forecasts = filters.has_7f_forecasts;
        }

        if (xlsx) {
            extraFilters.xlsx = 1;
        }

        return (await axios.get('cockpit/purchase-plan/product', {
            params: this.$_filterSetFiltersToParams(filters, extraFilters),
            responseType: xlsx ? 'blob' : undefined,
        })).data;
    }

    async getProductPurchasePlanOverview(filters, hideSpinner) {
        return (await axios.get('cockpit/purchase-plan/product/overview', {
            params: this.$_filterSetFiltersToParams(filters),
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getSalesAndStockStats(level, filters, xlsx, hideSpinner) {
        return (await axios.get(`cockpit/sales-and-stock/${level}`, {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null, ['forecasts']),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getSalesEvolution(filters, xlsx, hideSpinner) {
        return (await axios.get('cockpit/sales-evolution', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getForecasts(filters, xlsx, hideSpinner) {
        return (await axios.get('cockpit/forecasts', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getTimeToMarketStats(filters, xlsx, hideSpinner) {
        return (await axios.get('cockpit/time-to-market', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getDistributorsWithStats(filters) {
        return (await axios.get('cockpit/distributors', {
            params: this.$_filterSetFiltersToParams(filters)
        })).data;
    }

    async getRetailersWithStats(filters) {
        return (await axios.get('cockpit/retailers', {
            params: this.$_filterSetFiltersToParams(filters)
        })).data;
    }

    async getSalesFlash(filters, onlyImportant, xlsx, hideSpinner) {
        const params = this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null);

        if (onlyImportant) {
            params.important = true;
        }

        return (await axios.get('cockpit/sales-flash', {
            params,
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getStockConsolidation(filters, xlsx) {
        return (await axios.get('cockpit/stock-consolidation', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null),
            responseType: xlsx ? 'blob' : undefined,
        })).data;
    }

    async getMiscStats(hideSpinner) {
        return (await axios.get('cockpit/misc-stats', {
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getOpenOCsByDistributors(filters, hideSpinner) {
        return (await axios.get('cockpit/distributors/open-ocs', {
            params: this.$_filterSetFiltersToParams(filters, null, ['order_product_group_id']),
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getForecastsByDistributors(hideSpinner) {
        return (await axios.get('cockpit/distributors/forecasts', {
            __hideSpinner: hideSpinner,
        })).data;
    }

    // region B2C

    async getB2CAccounts(filters, xlsx, hideSpinner) {
        const result = (await axios.get('cockpit/b2c/accounts', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null, ['city', 'product_category', 'number_of_products']),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;

        return xlsx ? result : result.accounts;
    }

    async getB2CStatistics(filters, hideSpinner) {
        return (await axios.get('cockpit/b2c/statistics', {
            params: this.$_filterSetFiltersToParams(filters, null,  ['city', 'product_category', 'number_of_products']),
            __hideSpinner: hideSpinner,
        })).data;
    }

    async getB2CCountries(filters, xlsx, hideSpinner) {
        const result = (await axios.get('cockpit/b2c/countries', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null, ['city', 'product_category', 'number_of_products']),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;

        return xlsx ? result : result.countries;
    }

    async getB2CProducts(filters, xlsx, hideSpinner) {
        const result = (await axios.get('cockpit/b2c/products', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null, ['city', 'product_category', 'number_of_products']),
            responseType: xlsx ? 'blob' : undefined,
            __hideSpinner: hideSpinner,
        })).data;

        return xlsx ? result : result.products;
    }

    async getB2CProductAccounts(accountId) {
        return (await axios.get(`cockpit/b2c/accounts/${accountId}/products`))
            .data.product_accounts;
    }

    // endregion B2C

    // region Legal

    async getIPCOverview(year, xlsx) {
        return (await axios.get('cockpit/legal/certificates/overview', {
            params: Object.assign({ year }, xlsx ? {xlsx: 1} : {}),
            responseType: xlsx ? 'blob' : undefined,
        })).data;
    }

    async indexIPCs(filters, xlsx) {
        return (await axios.get('cockpit/legal/certificates', {
            params: this.$_filterSetFiltersToParams(filters, xlsx ? {xlsx: 1} : null, ['type', 'category', 'series', 'class']),
            responseType: xlsx ? 'blob' : undefined,
        })).data;
    }

    async getIPC(id) {
        return (await axios.get('cockpit/legal/certificates/' + id)).data.property_certificate;
    }

    async deleteIPC(id) {
        return (await axios.delete('cockpit/legal/certificates/' + id));
    }

    async indexLawyers(filters) {
        return (await axios.get('cockpit/legal/lawyers', {
            params: this.$_filterSetFiltersToParams(filters),
        })).data.lawyers;
    }

    async getLawyer(id) {
        return (await axios.get('cockpit/legal/lawyers/' + id)).data.lawyer;
    }

    async createLawyer(data) {
        return (await axios.post('cockpit/legal/lawyers', data)).data.lawyer;
    }

    async updateLawyer(id, data) {
        return (await axios.patch('cockpit/legal/lawyers/' + id, data)).data.lawyer;
    }

    async deleteLawyer(id) {
        return await axios.delete('cockpit/legal/lawyers/' + id);
    }

    async createIpc(data) {
        return (await axios.post('cockpit/legal/certificates', data)).data.property_certificate;
    }

    async updateIpc(id, data) {
        return (await axios.patch('cockpit/legal/certificates/' + id, data)).data.property_certificate;
    }

    async uploadIpcAttachment(id, formData) {
        return (await axios.post('cockpit/legal/certificates/' + id + '/attachments', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })).data.property_certificate;
    }

    async importIpcs(formData) {
        return (await axios.post('cockpit/legal/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })).data;
    }

    async deleteIpcAttachment(id) {
        return (await axios.delete('cockpit/legal/certificates/attachments/' + id));
    }

    // endregion Legal

    async downloadFile(url) {
        return (await axios.get(url, {
            responseType: 'blob',
        })).data;
    }

    // @todo refact

    $_filterSetFiltersToParams(filters, extra, extraKeys) {
        const params = {};

        if (filters.productGroup) {
            params.product_group_id = filters.productGroup;
        }

        if (filters.productSeries) {
            params.product_series_id = filters.productSeries;
        }

        if (filters.product) {
            params.product_id = filters.product;
        }

        if (filters.continent) {
            params.continent_id = filters.continent;
        }

        if (filters.country) {
            params.country_id = filters.country;
        }

        if (filters.distributor) {
            params.customer_id = filters.distributor;
        }

        if (filters.date && filters.date.startDate) {
            params.date = filters.date.startDate.toISOString();
        }

        if (filters.dateRange && filters.dateRange.startDate) {
            params.from_date = filters.dateRange.startDate.toISOString();
            params.to_date = filters.dateRange.endDate.toISOString();
        }

        if (filters.col) {
            params.col = filters.col;
        }

        if (filters.dir) {
            params.dir = filters.dir;
        }

        if (filters.page) {
            params.page = filters.page;
        }

        if (extra) {
            Object.assign(params, extra);
        }

        if (extraKeys) {
            for (const key of extraKeys) {
                params[key] = filters[key];
            }
        }

        return params;
    }
};
