<template>

    <div v-if="ipc">
        <h2>{{ ipc.reference }}</h2>

        <div class="d-flex justify-content-end align-items-end mb-1">
            <router-link :to="{ name: 'legal.ipc.edit', params: { id: ipc.id } }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                Modify this IP
            </router-link>
            <router-link :to="{ name: 'legal.ipc.manage-attachments', params: { id: ipc.id } }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                Manage files
            </router-link>
            <a href="#" @click="deleteIPC" class="text-primary font-weight-bold mr-4" v-if="canManage"
                >Delete this IP</a>
        </div>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>Product category</th>
                <th>Country</th>
                <th>Class</th>
                <th>Next renewal</th>
                <th>Proof of use</th>
                <th>Documents</th>
                <th>Last renewal</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ ipc.category }}</td>
                <td>{{ ipc.country_id }}</td>
                <td>{{ ipc.class }}</td>
                <td>{{ ipc.next_renewal | date }}</td>
                <td>{{ ipc.proof_of_use }}</td>
                <td>
                    <template v-if="ipc.attachments.length > 0">
                        <span class="cursor-pointer" @click="$bvModal.show('ip-documents-modal')">
                            <inline-svg src="/assets/icons/file--red.svg">
                            </inline-svg>
                        </span>
                    </template>
                    <template v-else>
                        -
                    </template>
                </td>
                <td>{{ ipc.last_renewal | date }}</td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th colspan="3" style="height: 25px;">Application</th>
                <th colspan="3" style="height: 25px;">1st registration</th>
                <th colspan="3" style="height: 25px;">Priority</th>
            </tr>
            <tr>
                <th style="height: 25px;">Application nr.</th>
                <th style="height: 25px;">Attachment</th>

                <th style="height: 25px;">Date</th>
                <th style="height: 25px;">Registration nr.</th>
                <th style="height: 25px;">Attachment</th>
                <th style="height: 25px;">Date</th>

                <th style="height: 25px;">Priority nr.</th>
                <th style="height: 25px;">Attachment</th>
                <th style="height: 25px;">Date</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ ipc.application_number }}</td>
                <td>
                    <span v-if="applicationAttachment" class="cursor-pointer" @click="downloadDocument(applicationAttachment)">
                        <inline-svg src="/assets/icons/file--red.svg">
                        </inline-svg>
                    </span>
                </td>
                <td>{{ ipc.application_date | date }}</td>

                <td>{{ ipc.registration_number }}</td>
                <td>
                    <span v-if="registrationAttachment" class="cursor-pointer" @click="downloadDocument(registrationAttachment)">
                        <inline-svg src="/assets/icons/file--red.svg">
                        </inline-svg>
                    </span>
                </td>
                <td>{{ ipc.first_registration_date | date }}</td>

                <td>{{ ipc.priority_number }}</td>
                <td>
                    <span v-if="priorityAttachment" class="cursor-pointer" @click="downloadDocument(priorityAttachment)">
                        <inline-svg src="/assets/icons/file--red.svg">
                        </inline-svg>
                    </span>
                </td>
                <td>{{ ipc.priority_date | date }}</td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>Status</th>
                <th>Last renewal</th>
                <th>CType</th>
                <th>Owner</th>
                <th>Renewal cost</th>
                <th>Interval / Periodicity</th>
                <th>Family</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ ipc.status_label }}</td>
                <td>{{ ipc.last_renewal | date }}</td>
                <td>{{ ipc.ctype }}</td>
                <td>{{ ipc.owner }}</td>
                <td>CHF {{ ipc.renewal_cost | price }}.-</td>
                <td>{{ ipc.periodicity }} ({{ ipc.current_period || '?' }} of {{ ipc.period_count }})</td>
                <td>{{ ipc.family }}</td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>Lawyer</th>
                <th>Comments</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <a v-if="ipc.lawyer" href="#" @click.prevent="$bvModal.show('lawyer-modal');" class="text-primary">
                        {{ ipc.lawyer.name }}
                    </a>
                    <template v-else>
                        —
                    </template>
                </td>
                <td>{{ ipc.comments }}</td>
            </tr>
            </tbody>
        </table>

        <documents-modal :ipc="ipc" />

        <lawyer-modal v-if="ipc.lawyer" :lawyer="ipc.lawyer" />
    </div>

</template>

<script>
import CurrentUserService from '../../../js/services/CurrentUserService';
import StatisticsService from '../../../js/services/StatisticsService';
import DocumentsModal from '../../components/legal/DocumentsModal';
import {downloadBlobResponse} from '../../../js/helpers';
import LawyerModal from '../../components/legal/LawyerModal';

export default {
    components: {
        LawyerModal,
        DocumentsModal,
    },
    data() {
        return {
            ipc: null,
            canManage: false,
        };
    },
    computed: {
        ipcId() {
            return this.$route.params.id;
        },
        applicationAttachment() {
            return this.ipc.attachments.find(a => a.type === 'application');
        },
        registrationAttachment() {
            return this.ipc.attachments.find(a => a.type === 'first_registration');
        },
        priorityAttachment() {
            return this.ipc.attachments.find(a => a.type === 'priority')
        },
    },
    watch: {
        async ipcId() {
            await this.loadIPC();
        },
    },
    async mounted() {
        this.canManage = (await CurrentUserService.get()).can.includes('manage-ipcs');
        await this.loadIPC();
    },
    methods: {
        async loadIPC() {
            this.ipc = await StatisticsService.getIPC(this.ipcId);
        },
        async downloadDocument(attachment) {
            downloadBlobResponse(
                await StatisticsService.downloadFile(attachment.url),
                attachment.file_name
            );
        },
        async deleteIPC() {
            await StatisticsService.deleteIPC(this.ipcId);

            await this.$router.replace({ name: 'legal.' + this.ipc.type });
        },
    },
};
</script>
