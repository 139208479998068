import { render, staticRenderFns } from "./UserInfoTable.vue?vue&type=template&id=6a430464&scoped=true&"
import script from "./UserInfoTable.vue?vue&type=script&lang=js&"
export * from "./UserInfoTable.vue?vue&type=script&lang=js&"
import style0 from "./UserInfoTable.vue?vue&type=style&index=0&id=6a430464&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a430464",
  null
  
)

export default component.exports