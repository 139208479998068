<template>
    <div class="y-scrollable-block position-relative overflow-auto" v-if="data">
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th class="text-left pl-2"><sortable-col-header col="name" v-model="sorting">Name</sortable-col-header></th>
                <th v-if="colSet.includes('product_category')"><sortable-col-header col="category" v-model="sorting">Product category</sortable-col-header></th>
                <th v-if="colSet.includes('series')"><sortable-col-header col="series" v-model="sorting">Series</sortable-col-header></th>
                <th v-if="colSet.includes('reference')"><sortable-col-header col="reference" v-model="sorting">Reference</sortable-col-header></th>
                <th v-if="colSet.includes('country_id')"><sortable-col-header col="country_id" v-model="sorting">Country</sortable-col-header></th>
                <th v-if="colSet.includes('class')">Class</th>
                <th v-if="colSet.includes('status')"><sortable-col-header col="status" v-model="sorting">Status</sortable-col-header></th>
                <th v-if="colSet.includes('next_renewal')"><sortable-col-header col="next_renewal" v-model="sorting">Next renewal</sortable-col-header></th>
                <th v-if="colSet.includes('proof_of_use')">Proof of use</th>
                <th v-if="colSet.includes('documents')">Documents</th>
                <th v-if="colSet.includes('last_renewal')"><sortable-col-header col="last_renewal" v-model="sorting">Last renewal</sortable-col-header></th>
                <th v-if="colSet.includes('lawyer')">Correspondence lawyer</th>
            </tr>
            </thead>
            <tbody>

            <tr class="cursor-pointer" v-for="ip in data">
                <td class="text-left pl-2" @click="goToDetails(ip)" :class="ip.is_due ? 'due' : ''">{{ ip.name }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('product_category')">{{ ip.category }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('series')">{{ ip.series }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('reference')">{{ ip.reference }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('country_id')">{{ ip.country_id }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('class')">{{ ip.class ? ip.class.replace(/,/g, ', ') : '' }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('status')">{{ ip.status }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('next_renewal')" :class="ip.is_due ? 'text-danger' : ''">{{ ip.next_renewal | date }}</td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('proof_of_use')">{{ ip.proof_of_use }}</td>
                <td v-if="colSet.includes('documents')">
                    <template v-if="ip.attachments.length > 0">
                        <span @click="openDocList(ip)">
                            <inline-svg src="/assets/icons/file--red.svg">
                            </inline-svg>
                        </span>
                    </template>
                    <template v-else>
                        —
                    </template>
                </td>
                <td @click="goToDetails(ip)" v-if="colSet.includes('last_renewal')">{{ ip.last_renewal | date }}</td>
                <td v-if="colSet.includes('lawyer')">
                    <a v-if="ip.lawyer" href="#" @click.prevent="openLawyerModal(ip)" class="text-primary">
                        {{ ip.lawyer.name }}
                    </a>
                    <template v-else>
                        —
                    </template>
                </td>
            </tr>

            </tbody>
        </table>

        <documents-modal :ipc="clickedIp" />

        <lawyer-modal v-if="clickedIp" :lawyer="clickedIp.lawyer" />
    </div>
    <div v-else class="no-data-available">
        No data available
    </div>
</template>

<style scoped>
.due {
    padding-left: 35px !important;
    background-image: url('/assets/icons/bell--red.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
}
</style>

<script>
import OverviewTableRow from './OverviewTableRow';
import DocumentsModal from '../../components/legal/DocumentsModal';
import LawyerModal from './LawyerModal';

export default {
    components: {
        LawyerModal,
        OverviewTableRow,
        DocumentsModal,
    },
    props: {
        data: {
            required: true,
            type: Array,
        },
        colSet: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            sorting: null,
            clickedIp: null,
        };
    },
    watch: {
        sorting() {
            this.$emit('sort', this.sorting);
        },
    },
    methods: {
        async openDocList(ip) {
            this.clickedIp = ip;
            await this.$nextTick();
            this.$bvModal.show('ip-documents-modal');
        },
        async openLawyerModal(ip) {
            this.clickedIp = ip;
            await this.$nextTick();
            this.$bvModal.show('lawyer-modal');
        },
        goToDetails(ip) {
            this.$router.push({ name: `legal.${ip.type}.details`, params: {id:ip.id} });
        },
    },
};
</script>
