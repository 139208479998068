var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ipc)?_c('div',[_c('h2',[_vm._v("Manage files")]),_vm._v(" "),_c('h3',[_vm._v("Upload new file")]),_vm._v(" "),_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.upload)}}},[(_vm.typeOptions)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("* Type")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.selectableTypes,"label":"label","reduce":function (type) { return type.id; },"clearable":false,"searchable":false},model:{value:(_vm.attachment.type),callback:function ($$v) {_vm.$set(_vm.attachment, "type", $$v)},expression:"attachment.type"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment.name),expression:"attachment.name"}],staticClass:"form-control",attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.attachment.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment, "name", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),(_vm.showFileInput)?_c('ValidationProvider',{ref:"fileValProvider",staticClass:"form-group",attrs:{"tag":"div","name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"file"}},[_vm._v("* File")]),_vm._v(" "),_c('input',{ref:"fileInput",staticClass:"form-control",attrs:{"id":"file","type":"file"},on:{"change":validate}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group mb-0"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("\n                    Upload attachment\n                ")])])],1)]}}],null,false,1103024388)}),_vm._v(" "),_c('h3',{staticClass:"mt-4"},[_vm._v("Uploaded files")]),_vm._v(" "),_c('table',{staticClass:"table table-bordered table-layout-fixed"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.ipc.attachments),function(attachment){return _c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.typeOptions.find(function (t) { return t.id === attachment.type; }).label))])]),_vm._v(" "),_c('td',[_vm._v(_vm._s(attachment.name))]),_vm._v(" "),_c('td',[_c('button',{staticClass:"btn btn-sm btn-danger btn-icon",on:{"click":function($event){return _vm.deleteAttachment(attachment)}}},[_c('inline-svg',{attrs:{"src":"/assets/icons/trash.svg"}})],1)])])}),0)])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col',{staticStyle:{"width":"150px"}}),_vm._v(" "),_c('col'),_vm._v(" "),_c('col',{staticStyle:{"width":"150px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Type")]),_vm._v(" "),_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Actions")])])])}]

export { render, staticRenderFns }