<template>
    <div v-if="isEverythingLoaded">
        <h2>Settings</h2>

        <h3>Users</h3>

        <div v-for="user in users" class="row mb-3">
            <div class="col-12 col-md-6 settings-email">{{ user.email }}</div>
            <div v-if="currentUser.can.includes('delete-user')" class="col-12 col-md-6">
                <button class="btn btn-link btn-link-regular text-danger font-weight-bold" @click="confirmDeleteUser(user)">Delete user</button>
            </div>
        </div>

        <div v-if="currentUser.can.includes('add-user')" class="mt-4" style="max-width: 280px;">
            <button class="btn btn-primary btn-block" @click="$bvModal.show('newUserModal')">
                Add new user
            </button>
        </div>

<!--        <hr class="mt-4" />-->

<!--        <h3>Notifications</h3>-->

<!--        <div class="row header-row mb-2">-->
<!--            <div class="col-2">Element</div>-->
<!--            <div class="col-2">Product</div>-->
<!--            <div class="col-2">Action</div>-->
<!--            <div class="col-2">Amount</div>-->
<!--            <div class="col-2">In app notification</div>-->
<!--            <div class="col-2">E-mail notification</div>-->
<!--        </div>-->

<!--        <notification-setting v-for="setting in settings" :key="setting.name" :data="setting" />-->

        <b-modal id="newUserModal" title="Add new user" centered hide-footer>
            <ValidationObserver v-slot="{ handleSubmit }" ref="newUserFormValidator">
                <form class="form-horizontal" @submit.prevent="handleSubmit(invite)">
                    <ValidationProvider tag="div" class="form-group" name="email" rules="required|email" v-slot="{ errors }">
                        <input type="text" class="form-control" placeholder="Type e-mail address" v-model="newUserFormData.email">
                        <div class="text-danger">{{ errors[0] }}</div>
                    </ValidationProvider>

                    <div class="form-group row align-items-center">
                        <label for="canAccessBusiness" class="col-9 col-form-label">
                            Can access Business Cockpit
                        </label>
                        <div class="col-sm-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="canAccessBusiness" v-model="newUserFormData.canAccessBusiness">
                                <label class="custom-control-label" for="canAccessBusiness"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label for="canAddUserCheckbox" class="col-9 col-form-label">
                            Can add new users
                        </label>
                        <div class="col-sm-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="canAddUserCheckbox" v-model="newUserFormData.canAddUser">
                                <label class="custom-control-label" for="canAddUserCheckbox"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label for="canDeleteUserCheckbox" class="col-9 col-form-label">
                            Can delete users
                        </label>
                        <div class="col-sm-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="canDeleteUserCheckbox" v-model="newUserFormData.canDeleteUser">
                                <label class="custom-control-label" for="canDeleteUserCheckbox"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label for="canEdit7fForecastCheckbox" class="col-9 col-form-label">
                            Can edit 7F forecasts
                        </label>
                        <div class="col-sm-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="canEdit7fForecastCheckbox" v-model="newUserFormData.canEdit7fForecast">
                                <label class="custom-control-label" for="canEdit7fForecastCheckbox"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row align-items-center">
                        <label for="canManageIPCsCheckbox" class="col-9 col-form-label">
                            Can manage IPCs
                        </label>
                        <div class="col-sm-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="canManageIPCsCheckbox" v-model="newUserFormData.canManageIPCs">
                                <label class="custom-control-label" for="canManageIPCsCheckbox"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-0">
                        <button type="submit" class="btn btn-primary btn-block">
                            Add new user
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>

        <simple-modal ref="newUserSuccessModal" icon="success">
            An invitation link has been successfully sent to the given e-mail address.
        </simple-modal>

        <simple-modal ref="deleteUserConfirmationModal" icon="warning" confirm
                      @ok="deleteUser(deletableUser)" v-if="deletableUser">
            Are you sure you want to permanently delete {{ deletableUser.email }}?
        </simple-modal>
    </div>
</template>

<style lang="scss">
.settings-email {
    word-break: break-all;
    max-width: 23em;
}
</style>

<style scoped>
.header-row {
    font-weight: 600;
}
</style>

<script>
import CurrentUserService from '../../js/services/CurrentUserService';
import UserService from '../../js/services/UserService';
import NotificationSetting from '../components/settings/NotificationSetting'

export default {
    components: {
        NotificationSetting
    },
    data() {
        return {
            currentUser: null,
            users: null,
            newUserFormData: null,
            deletableUser: null,

            settings: [
                {
                    element: 'Retailer time to market',
                    product: 'All',
                    action: '<',
                    amount: 123,
                    inAppNotification: true,
                    emailNotification: false
                },
                {
                    element: 'Sales',
                    product: 'M2/02',
                    action: '=',
                    amount: 123,
                    inAppNotification: true,
                    emailNotification: false
                },
                {
                    element: 'Warranty activation',
                    product: 'P01/01',
                    action: '>',
                    amount: 123,
                    inAppNotification: true,
                    emailNotification: false
                }
            ]
        };
    },
    computed: {
        isEverythingLoaded() {
            return this.currentUser && this.users;
        },
    },
    async mounted() {
        this.resetNewUserForm();

        const data = await Promise.all([
            CurrentUserService.get(),
            UserService.index(),
        ]);

        this.currentUser = data[0];
        this.users = data[1];
    },
    methods: {
        async invite() {
            const can = [];

            if (this.newUserFormData.canAccessBusiness) {
                can.push('business');
            }
            if (this.newUserFormData.canAddUser) {
                can.push('add-user');
            }
            if (this.newUserFormData.canDeleteUser) {
                can.push('delete-user');
            }
            if (this.newUserFormData.canEdit7fForecast) {
                can.push('edit-7f-forecast');
            }
            if (this.newUserFormData.canManageIPCs) {
                can.push('manage-ipcs');
            }

            try {
                const user = await UserService.invite(this.newUserFormData.email, can);
                this.users.push(user);
            } catch (e) {
                this.displayFormErrorsOrThrow(e, 'newUserFormValidator');
                return;
            }

            await this.$bvModal.hide('newUserModal');

            this.$refs.newUserSuccessModal.open();

            this.resetNewUserForm();
        },
        async confirmDeleteUser(user) {
            this.deletableUser = user;
            await this.$nextTick();
            this.$refs.deleteUserConfirmationModal.open();
        },
        async deleteUser(user) {
            await UserService.delete(user.id);

            this.users.splice(
                this.users.indexOf(user), 1
            );
        },
        resetNewUserForm() {
            this.newUserFormData = {
                email: '',
                canAccessBusiness: true,
                canAddUser: true,
                canDeleteUser: true,
                canEdit7fForecast: false,
                canManageIPCs: false,
            };
        },
    },
};
</script>
