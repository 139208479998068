import LegalService from './services/LegalService';

require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import { ModalPlugin, CollapsePlugin, FormRadioPlugin, FormCheckboxPlugin, IconsPlugin, SkeletonPlugin } from 'bootstrap-vue';
import SweetModal from 'sweet-modal-vue/src/plugin';
import VueGoogleCharts from 'vue-google-charts'
import {InlineSvgComponent} from 'vue-inline-svg';
import vSelect from 'vue-select'
import { routes } from './routes';
import { handleError } from './error-handler';
import TheSpinnerModal from '../vue/components/TheSpinnerModal';
import TheErrorModal from '../vue/components/TheErrorModal';
import { ValidationProvider, ValidationObserver, extend as extendVeeValidate, setInteractionMode as setVeeValidateInteractionMode } from 'vee-validate';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import SimpleModal from '../vue/components/SimpleModal';
import NumberValue from '../vue/components/NumberValue';
import SearchInput from '../vue/components/SearchInput';
import DateRangePicker from '../vue/components/DateRangePicker';
import FilterSet from '../vue/components/FilterSet';
import ExportButton from '../vue/components/ExportButton';
import * as helpers from './helpers';
import frag from 'vue-frag';
import NotificationService from './services/NotificationService';
import SortableColHeader from '../vue/components/SortableColHeader';
import AuthService from './services/AuthService';
import EventBus from './event-bus';
import CurrentUserService from './services/CurrentUserService';

Vue.directive('frag', frag);

Vue.use(VueRouter);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(IconsPlugin);
Vue.use(SkeletonPlugin);
Vue.use(SweetModal);
Vue.use(VueGoogleCharts);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('inline-svg', InlineSvgComponent);
Vue.component('v-select', vSelect);
Vue.component('simple-modal', SimpleModal);
Vue.component('number-value', NumberValue);
Vue.component('search-input', SearchInput);
Vue.component('date-range-picker', DateRangePicker);
Vue.component('filter-set', FilterSet);
Vue.component('export-button', ExportButton);
Vue.component('sortable-col-header', SortableColHeader);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (! to.name.startsWith('b2')) {
        next();
    } else {
        const user = await CurrentUserService.get();

        if (! user || ! user.can.includes('business')) {
            next({ name: 'legal.overview'});
        } else {
            next();
        }
    }
});

extendVeeValidate('required', {
    ...required,
    message: 'This field is required.',
});
extendVeeValidate('email', {
    ...email,
    message: 'This e-mail address is invalid.',
});
extendVeeValidate('confirmed', {
    ...confirmed,
    message: 'The confirmation does not match.',
});
extendVeeValidate('min', {
    ...min,
    message: 'This should be at least {length} characters long.',
});

setVeeValidateInteractionMode('passive');

Vue.filter('month', monthNumber => {
    return helpers.MONTH_NAMES[monthNumber];
});
Vue.filter('percents', (number, forceSign = true) => {
    return helpers.formatPercents(number, forceSign);
});
Vue.filter('number', (number, maxDecimals = 1) => {
    return helpers.formatNumber(number, maxDecimals);
});
Vue.filter('price', (price, maxDecimals = 1) => {
    return helpers.formatPrice(price, maxDecimals);
});
Vue.filter('date', date => {
    return helpers.formatDate(date);
});
Vue.filter('datetime', date => {
    return helpers.formatDateTime(date);
});
Vue.filter('dash', data => {
    return helpers.displayDash(data);
});

Vue.mixin({
    methods: {
        displayFormErrorsOrThrow(e, ref = 'formValidator') {
            if (e.response && e.response.status === 422 && e.response.data && e.response.data.errors) {
                this.$refs[ref].setErrors(e.response.data.errors);
            } else {
                throw e;
            }
        },
    },
});

NotificationService.boot();
LegalService.boot();

if (AuthService.getAccessToken()) {
    EventBus.$emit('got-access-token');
}

new Vue({
    errorCaptured: handleError,
    el: '#app',
    router,
    components: {
        TheSpinnerModal,
        TheErrorModal,
    },
});
