<template>
    <nav>
        <img src="/assets/images/sevenfriday-logo--white.svg" class="sidebar-logo" alt="Sevenfriday">

        <ul>
            <template v-for="(item, idx) in menu">
                <li v-if="! item.can || can.includes(item.can)">
                    <template v-if="item.children">
                        <a v-b-toggle="`subnav${idx}`"
                           :class="['dropdown-toggle', { 'selected': item === selected1stLevel }]">
                            {{ item.title }}
                        </a>
                        <b-collapse tag="ul" visible :id="`subnav${idx}`">
                            <li v-for="subitem in item.children">
                                <router-link :to="subitem.to" :class="{ 'selected': subitem === selected2ndLevel }">
                                    {{ subitem.title }}
                                    <span v-if="subitem.badge && badgeData[subitem.badge]" class="font-weight-normal text-danger">
                                        {{ badgeData[subitem.badge] }}
                                    </span>
                                </router-link>
                            </li>
                        </b-collapse>
                    </template>
                    <template v-else-if="item.action">
                        <a @click.prevent="item.action">
                            {{ item.title }}
                        </a>
                    </template>
                    <template v-else>
                        <router-link :to="item.to" :class="{ 'selected': item === selected1stLevel }">
                            {{ item.title }}
                            <span v-if="item.badge && badgeData[item.badge]" class="font-weight-normal text-danger">
                                {{ badgeData[item.badge] }}
                            </span>
                        </router-link>
                    </template>
                </li>
            </template>
        </ul>
    </nav>
</template>

<style scoped>
/* style in _the_sidebar.scss */
</style>

<script>
import AuthService from '../../js/services/AuthService';
import NotificationService from '../../js/services/NotificationService';
import EventBus from '../../js/event-bus';
import LegalService from '../../js/services/LegalService';
import CurrentUserService from '../../js/services/CurrentUserService';

export default {
    data() {
        EventBus.$on('unread-count-refreshed', count => this.badgeData.unreadCount = count);

        EventBus.$on('due-ip-count-refreshed', count => {
            this.badgeData.dueTrademarkIpCount = count.trademark;
            this.badgeData.dueDesignIpCount = count.design;
        });

        return {
            can: [],
            menu: [
                {
                    title: 'B2B Reports',
                    can: 'business',
                    children: [
                        { title: 'Dashboard', to: { name: 'b2b.dashboard' } },
                        { title: 'Master Purchase Plan', to: { name: 'b2b.plan' } },
                        { title: 'Sales/Stock', to: { name: 'b2b.sales-and-stock' } },
                        { title: 'Time to market', to: { name: 'b2b.time-to-market' } },
                        { title: 'Distributors', to: { name: 'b2b.distributors' } },
                        // { title: 'Retailers', to: { name: 'b2b.retailers' } },
                        { title: 'Daily sales flash', to: { name: 'b2b.sales-flash' } },
                        { title: 'Stock consolidation', to: { name: 'b2b.stock-consolidation' } },
                    ],
                },
                { title: 'B2C Reports', to: { name: 'b2c.reports' }, can: 'business' },
                { title: 'Settings', to: { name: 'settings' } },
                { title: 'Notifications', to: { name: 'notifications' }, badge: 'unreadCount' },
                {
                    title: 'Legal Cockpit',
                    children: [
                        { title: 'Overview', to: { name: 'legal.overview' } },
                        { title: 'IP Trademark', to: { name: 'legal.trademark' }, badge: 'dueTrademarkIpCount' },
                        { title: 'IP Design', to: { name: 'legal.design' }, badge: 'dueDesignIpCount' },
                        { title: 'Lawyers', to: { name: 'legal.lawyer' } },
                    ],
                    checkOtherRouteMatches: route => route.name.indexOf('legal.') > -1,
                },
                { title: 'Logout', action: this.logout }
            ],
            selected1stLevel: null,
            selected2ndLevel: null,

            badgeData: {
                unreadCount: NotificationService.unreadCount,
                dueTrademarkIpCount: LegalService.dueCount.trademark,
                dueDesignIpCount: LegalService.dueCount.design,
            },
        };
    },
    async mounted() {
        this.updateSelectedMenuitems(this.$route);

        this.$router.afterEach(to => {
            this.updateSelectedMenuitems(to);
        });

        const user = await CurrentUserService.get();

        if (user) {
            this.can = user.can;
        } else {
            this.can = [];
        }
    },
    methods: {
        async logout() {
            await AuthService.logout();

            this.$router.replace({ name: 'auth.login' });
        },
        updateSelectedMenuitems(route) {
            for (const item of this.menu) {
                if (item.children) {
                    for (const subitem of item.children) {
                        if (this.checkRouteMatch(subitem.to, route)) {
                            this.selected1stLevel = item;
                            this.selected2ndLevel = subitem;
                            return;
                        }
                    }

                    if (item.checkOtherRouteMatches && item.checkOtherRouteMatches(route)) {
                        this.selected1stLevel = item;
                        this.selected2ndLevel = null;
                        return;
                    }
                } else if (this.checkRouteMatch(item.to, route)) {
                    this.selected1stLevel = item;
                    this.selected2ndLevel = null;
                    return;
                }
            }

            this.selected1stLevel = null;
            this.selected2ndLevel = null;
        },
        checkRouteMatch(menuitemDestination, currentRoute) {
            return currentRoute.name === menuitemDestination.name
                || currentRoute.name.startsWith(menuitemDestination.name + '.');
        }
    },
}
</script>
