<template>
    <div class="table-container flex-grow-1">
        <table id="userInfoTable" class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed" v-if="data">
            <colgroup>
                <col>
                <col style="width: 56px;">
                <col>
                <col>
                <col>
                <col style="width: 120px;">
                <col style="width: 120px;">
                <col style="width: 100px;">
                <col style="width: 120px;">
                <col style="width: 90px;">
                <col style="width: 90px;">
                <col style="width: 90px;">
            </colgroup>
            <thead>
            <tr>
                <th><sortable-col-header col="name" v-model="sorting">Name</sortable-col-header></th>
                <th><sortable-col-header col="age" v-model="sorting">Age</sortable-col-header></th>
                <th><sortable-col-header col="city" v-model="sorting">City</sortable-col-header></th>
                <th><sortable-col-header col="country" v-model="sorting">Country</sortable-col-header></th>
                <th>Email</th>
                <th><sortable-col-header col="created_at" v-model="sorting">Acc creation</sortable-col-header></th>
                <th><sortable-col-header col="receipts_min_created_at" v-model="sorting">Warranty ext act</sortable-col-header></th>
                <th>Newsletter</th>
                <th><sortable-col-header col="last_visit" v-model="sorting">Last visit</sortable-col-header></th>
                <th><sortable-col-header col="app_opens_count" v-model="sorting">Visits</sortable-col-header></th>
                <th><sortable-col-header col="vip_visits_count" v-model="sorting">VIP visits</sortable-col-header></th>
                <th><sortable-col-header col="product_accounts_count" v-model="sorting">Prod reg</sortable-col-header></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in data">
                <td style="word-break: break-all;">{{ record.name }}</td>
                <td>{{ record.age === null ? '-' : record.age }}</td>
                <td style="word-break: break-all;">{{ record.city || '-' }}</td>
                <td style="word-break: break-all;">{{ record.country || '-' }}</td>
                <td style="word-break: break-all;">
                    <a :href='`mailto:${record.email}`'>{{ record.email }}</a>
                </td>
                <td>{{ record.created_at | datetime }}</td>
                <td>{{ record.receipts_min_created_at | datetime }}</td>
                <td>{{ record.newsletter ? 'yes' : '' }}</td>
                <td>{{ record.last_visit | datetime }}</td>
                <td>{{ record.app_opens_count }}</td>
                <td>{{ record.vip_visits_count | number }}</td>
                <td @click="() => showModal(record)">{{ record.product_accounts_count | number }}</td>
            </tr>
            </tbody>
        </table>
        <b-skeleton-table v-else :rows="10" :columns="6"
                          :table-props="{ bordered: true }" />

        <b-modal id="app-related-info-modal" scrollable ok-only>
            <h3>Product details</h3>

            <div class="d-flex flex-column">
                <span><b>User:</b> {{ selectedRecord.name }}</span>
                <span><b>Products:</b></span>

                <table v-for="productAccount of selectedProductAccounts" class="product-details-table mb-3">
                    <tr>
                        <td><b>Model:</b></td>
                        <td>{{ productAccount.product.name }}</td>
                    </tr>
                    <tr>
                        <td><b>Time of registration:</b></td>
                        <td>{{ productAccount.created_at | datetime }}</td>
                    </tr>
                    <tr>
                        <td><b>Product number:</b></td>
                        <td>{{ productAccount.product.product_number }}</td>
                    </tr>
                    <tr>
                        <td><b>Warranty picture:</b></td>
                        <td>
                            <a v-if="productAccount.receipt_url" :href="productAccount.receipt_url" target="_blank">
                                <img :src="productAccount.receipt_thumb_url" width="100%" />
                            </a>
                            <span v-else>None</span>
                        </td>
                    </tr>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>
.table-container {
    position: relative;
    overflow-y: auto;
    height: 90vh;
}

.table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: auto;

    margin-top: 0;
    margin-bottom: 0;

    width: 100%;
    min-width: 1290px;
}

.table tr td:last-child {
    cursor: pointer;
}

.product-details-table {
    margin-left: 25px;
}

.product-details-table td {
    vertical-align: top;
    width: 50%;
    margin-bottom: 3px
}
</style>

<script>
import StatisticsService from '../../../../js/services/StatisticsService';

export default {
    props: {
        data: {
            required: false,
            type: Array,
        }
    },
    data() {
        return {
            selectedRecord: {},
            selectedProductAccounts: null,
            sorting: null,
        }
    },
    watch: {
        sorting() {
            this.$emit('sort', this.sorting);
        },
    },
    methods: {
        async showModal(record) {
            this.selectedProductAccounts = await StatisticsService.getB2CProductAccounts(record.id);
            this.selectedRecord = record;
            this.$bvModal.show('app-related-info-modal');
        }
    }
};
</script>
