<template>
    <div class="y-scrollable-block-layout">
        <h2>{{ heading }}</h2>

        <div class="d-flex justify-content-between align-items-end mb-1">
            <filter-set :filters="['dateRange', 'legal']" v-model="filters" @filter-change="handleFilterChange">
            </filter-set>

            <div>
                <router-link :to="{ name: 'legal.ipc.create' }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                    Add IP
                </router-link>
                <router-link :to="{ name: 'legal.ipc.import' }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                    Import
                </router-link>
                <a href="#" class="text-primary font-weight-bold"
                   @click="exportXlsx">Export all available columns to XLS</a>
            </div>
        </div>

        <certificates-table v-if="data" :col-set="colSet" :data="data"
                            @sort="handleSorting" />
    </div>
</template>

<script>
import CurrentUserService from '../../../js/services/CurrentUserService';
import CertificatesTable from '../../components/legal/CertificatesTable';
import StatisticsService from '../../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../../js/helpers';

export default {
    components: {
        CertificatesTable,
    },
    data() {
        return {
            data: null,
            canManage: false,
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            },
            sorting: null,
        };
    },
    computed: {
        type() {
            return this.$route.meta.type;
        },
        heading() {
            return {
                trademark: 'IP Trademark',
                design: 'IP Design',
            }[this.type];
        },
        colSet() {
            if (this.type === 'trademark') {
                return ['country_id', 'class', 'next_renewal', 'proof_of_use', 'documents', 'last_renewal'];
            } else if (this.type === 'design') {
                return ['product_category', 'country_id', 'next_renewal'];
            } else {
                return [];
            }
        },
    },
    watch: {
        async type() {
            await this.loadIPCs();
        },
    },
    async mounted() {
        this.canManage = (await CurrentUserService.get()).can.includes('manage-ipcs');
        await this.loadIPCs();
    },
    methods: {
        async loadIPCs() {
            this.data = (await StatisticsService.indexIPCs(
                Object.assign(
                    { type: this.type },
                    this.filters,
                    this.sorting || {}
                ),
            )).property_certificates;
        },
        async handleFilterChange(change) {
            await this.loadIPCs();
        },
        async handleSorting(sorting) {
            this.sorting = sorting;
            await this.loadIPCs();
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.indexIPCs(
                    Object.assign(
                        { type: this.type },
                        this.filters,
                        this.sorting || {}
                    ),
                    true
                ),
                this.heading.replace(' ', '_') + '.xlsx'
            );
        },
    },
};
</script>
