<template>

    <div v-if="ipc">
        <h2>{{ heading }}</h2>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form @submit.prevent="handleSubmit(send)">
                <ValidationProvider tag="div" class="form-group" name="type" rules="required" v-slot="{ errors }">
                    <label> * Type</label>
                    <v-select v-model="ipc.type"
                              :options="typeOptions"
                              label="label"
                              :reduce="type => type.id"
                              :clearable="false"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="country_id" rules="required" v-slot="{ errors }" v-if="countryOptions">
                    <label>* Country</label>
                    <v-select v-model="ipc.country_id"
                              :options="countryOptions"
                              label="name"
                              :reduce="country => country.id"
                              :clearable="false"
                              :searchable="true">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="periodicity" rules="required" v-slot="{ errors }">
                    <label for="periodicity">* Periodicity (number of years in a period)</label>
                    <input id="periodicity" type="number" min="1" class="form-control" v-model="ipc.periodicity">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="years_till_deadline" rules="required" v-slot="{ errors }">
                    <label for="years_till_deadline">* Years until deadline</label>
                    <input id="years_till_deadline" type="number" :min="ipc.periodicity" class="form-control" v-model="ipc.years_till_deadline">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="renewal_cost" rules="required" v-slot="{ errors }">
                    <label for="renewal_cost">* Renewal cost (CHF)</label>
                    <input id="renewal_cost" type="number" class="form-control" v-model="ipc.renewal_cost">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="name" v-slot="{ errors }">
                    <label for="name">Name</label>
                    <input id="name" type="text" class="form-control" v-model="ipc.name">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="lawyer_id" v-slot="{ errors }" v-if="lawyerOptions">
                    <label>Lawyer</label>
                    <v-select v-model="ipc.lawyer_id"
                              :options="lawyerOptions"
                              label="name"
                              :reduce="lawyer => lawyer.id"
                              :clearable="true"
                              :searchable="true">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="application_number" v-slot="{ errors }">
                    <label for="application_number">Application number</label>
                    <input id="application_number" type="text" class="form-control" v-model="ipc.application_number">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="application_date" v-slot="{ errors }">
                    <label>Application date</label>
                    <date-range-picker v-model="ipc.application_date" :range="false"
                                       class="d-block" :clearable="true">
                    </date-range-picker>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="registration_number" v-slot="{ errors }">
                    <label for="registration_number">Registration number</label>
                    <input id="registration_number" type="text" class="form-control" v-model="ipc.registration_number">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="first_registration_date" v-slot="{ errors }">
                    <label>First registration date</label>
                    <date-range-picker v-model="ipc.first_registration_date" :range="false"
                                       class="d-block" :clearable="true">
                    </date-range-picker>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="priority_number" v-slot="{ errors }">
                    <label for="priority_number">Priority number</label>
                    <input id="priority_number" type="text" class="form-control" v-model="ipc.registration_number">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="priority_date" v-slot="{ errors }">
                    <label>Priority date</label>
                    <date-range-picker v-model="ipc.priority_date" :range="false"
                                       class="d-block" :clearable="true">
                    </date-range-picker>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="last_renewal" v-slot="{ errors }">
                    <label>Last renewal</label>
                    <date-range-picker v-model="ipc.last_renewal" :range="false"
                                       class="d-block" :clearable="true">
                    </date-range-picker>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="category" v-slot="{ errors }">
                    <label for="category">Category</label>
                    <input id="category" type="text" class="form-control" v-model="ipc.category">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="series" v-slot="{ errors }">
                    <label for="series">Series</label>
                    <input id="series" type="text" class="form-control" v-model="ipc.series">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="class" v-slot="{ errors }">
                    <label>Class(es)</label>
                    <v-select v-model="ipc.class"
                              :options="classOptions"
                              :multiple="true"
                              :clearable="true"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="reference" v-slot="{ errors }">
                    <label for="reference">Reference</label>
                    <input id="reference" type="text" class="form-control" v-model="ipc.reference">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="proof_of_use" v-slot="{ errors }">
                    <label for="proof_of_use">Proof of use</label>
                    <input id="proof_of_use" type="text" class="form-control" v-model="ipc.proof_of_use">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="status" v-slot="{ errors }" v-if="statusOptions">
                    <label>Status</label>
                    <v-select v-model="ipc.status"
                              :options="statusOptions"
                              label="label"
                              :reduce="status => status.id"
                              :clearable="true"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="extention" v-slot="{ errors }">
                    <label for="extention">Extention</label>
                    <input id="extention" type="text" class="form-control" v-model="ipc.extention">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="ctype" v-slot="{ errors }">
                    <label>Ctype</label>
                    <v-select v-model="ipc.ctype"
                              :options="ctypeOptions"
                              :clearable="true"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="owner" v-slot="{ errors }">
                    <label for="owner">Owner</label>
                    <input id="owner" type="text" class="form-control" v-model="ipc.owner">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="family" v-slot="{ errors }">
                    <label for="family">Family</label>
                    <input id="family" type="text" class="form-control" v-model="ipc.family">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="comments" v-slot="{ errors }">
                    <label for="comments">Comments</label>
                    <textarea id="comments" class="form-control" v-model="ipc.comments"></textarea>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        {{ heading }}
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>

</template>

<script>
import StatisticsService from '../../../js/services/StatisticsService';
import GeoService from '../../../js/services/GeoService';
import moment from 'moment';
import LegalService from '../../../js/services/LegalService';

export default {
    data() {
        return {
            ipc: null,
            countryOptions: null,
            lawyerOptions: null,
            statusOptions: null,
            classOptions: null,

            typeOptions: [
                { id: 'trademark', label: 'Trademark' },
                { id: 'design', label: 'Design' },
            ],

            ctypeOptions: [
                'NAT', 'IR',
            ],
        };
    },
    computed: {
        heading() {
            return this.ipcId ? 'Edit IPC' : 'Create IPC';
        },
        ipcId() {
            return this.$route.params.id;
        },
    },
    watch: {
        async ipcId() {
            await this.loadIpc();
        },
    },
    async mounted() {
        this.countryOptions = await GeoService.getCountries();
        this.lawyerOptions = await StatisticsService.indexLawyers({ col: 'name', dir: 'asc' });

        const meta = await LegalService.getLegalMeta();

        this.statusOptions = meta.statuses;
        this.classOptions = meta.classes;

        await this.loadIpc();
    },
    methods: {
        async loadIpc() {
            if (this.ipcId) {
                const ipc = await StatisticsService.getIPC(this.ipcId);

                for (const attr of ['application_date', 'first_registration_date', 'priority_date', 'last_renewal']) {
                    const date = ipc[attr] ? moment(ipc[attr]).toDate() : null;

                    ipc[attr] = {
                        startDate: date,
                        endDate: date,
                    };
                }

                if (ipc.class) {
                    ipc.class = ipc.class.split(',');
                }

                ipc.renewal_cost = ipc.renewal_cost / 100;

                this.ipc = ipc;
            } else {
                this.ipc = {
                    periodicity: 1,
                    years_till_deadline: 1,
                    application_date: {},
                    first_registration_date: {},
                    priority_date: {},
                    last_renewal: {},
                };
            }
        },
        async send() {
            const ipc = Object.assign({}, this.ipc);

            for (const attr of ['application_date', 'first_registration_date', 'priority_date', 'last_renewal']) {
                ipc[attr] = ipc[attr].startDate ? moment(ipc[attr].startDate).format('YYYY-MM-DD') : null;
            }

            ipc.renewal_cost = parseInt(ipc.renewal_cost) * 100;
            ipc.periodicity = parseInt(ipc.periodicity);
            ipc.years_till_deadline = parseInt(ipc.years_till_deadline);
            ipc.class = ipc.class ? ipc.class.join(',') : null;

            try {
                if (this.ipcId) {
                    await StatisticsService.updateIpc(this.ipcId, ipc);
                } else {
                    await StatisticsService.createIpc(ipc);
                }

                await LegalService.refresh();

                await this.$router.push({name: 'legal.' + ipc.type});
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
            }
        },
    },
};
</script>
