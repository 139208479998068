var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ipc)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" * Type")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.typeOptions,"label":"label","reduce":function (type) { return type.id; },"clearable":false,"searchable":false},model:{value:(_vm.ipc.type),callback:function ($$v) {_vm.$set(_vm.ipc, "type", $$v)},expression:"ipc.type"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),(_vm.countryOptions)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"country_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("* Country")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.countryOptions,"label":"name","reduce":function (country) { return country.id; },"clearable":false,"searchable":true},model:{value:(_vm.ipc.country_id),callback:function ($$v) {_vm.$set(_vm.ipc, "country_id", $$v)},expression:"ipc.country_id"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"periodicity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"periodicity"}},[_vm._v("* Periodicity (number of years in a period)")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.periodicity),expression:"ipc.periodicity"}],staticClass:"form-control",attrs:{"id":"periodicity","type":"number","min":"1"},domProps:{"value":(_vm.ipc.periodicity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "periodicity", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"years_till_deadline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"years_till_deadline"}},[_vm._v("* Years until deadline")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.years_till_deadline),expression:"ipc.years_till_deadline"}],staticClass:"form-control",attrs:{"id":"years_till_deadline","type":"number","min":_vm.ipc.periodicity},domProps:{"value":(_vm.ipc.years_till_deadline)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "years_till_deadline", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"renewal_cost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"renewal_cost"}},[_vm._v("* Renewal cost (CHF)")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.renewal_cost),expression:"ipc.renewal_cost"}],staticClass:"form-control",attrs:{"id":"renewal_cost","type":"number"},domProps:{"value":(_vm.ipc.renewal_cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "renewal_cost", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.name),expression:"ipc.name"}],staticClass:"form-control",attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.ipc.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "name", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),(_vm.lawyerOptions)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"lawyer_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Lawyer")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.lawyerOptions,"label":"name","reduce":function (lawyer) { return lawyer.id; },"clearable":true,"searchable":true},model:{value:(_vm.ipc.lawyer_id),callback:function ($$v) {_vm.$set(_vm.ipc, "lawyer_id", $$v)},expression:"ipc.lawyer_id"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"application_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"application_number"}},[_vm._v("Application number")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.application_number),expression:"ipc.application_number"}],staticClass:"form-control",attrs:{"id":"application_number","type":"text"},domProps:{"value":(_vm.ipc.application_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "application_number", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"application_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Application date")]),_vm._v(" "),_c('date-range-picker',{staticClass:"d-block",attrs:{"range":false,"clearable":true},model:{value:(_vm.ipc.application_date),callback:function ($$v) {_vm.$set(_vm.ipc, "application_date", $$v)},expression:"ipc.application_date"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"registration_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"registration_number"}},[_vm._v("Registration number")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.registration_number),expression:"ipc.registration_number"}],staticClass:"form-control",attrs:{"id":"registration_number","type":"text"},domProps:{"value":(_vm.ipc.registration_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "registration_number", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"first_registration_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("First registration date")]),_vm._v(" "),_c('date-range-picker',{staticClass:"d-block",attrs:{"range":false,"clearable":true},model:{value:(_vm.ipc.first_registration_date),callback:function ($$v) {_vm.$set(_vm.ipc, "first_registration_date", $$v)},expression:"ipc.first_registration_date"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"priority_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"priority_number"}},[_vm._v("Priority number")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.registration_number),expression:"ipc.registration_number"}],staticClass:"form-control",attrs:{"id":"priority_number","type":"text"},domProps:{"value":(_vm.ipc.registration_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "registration_number", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"priority_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Priority date")]),_vm._v(" "),_c('date-range-picker',{staticClass:"d-block",attrs:{"range":false,"clearable":true},model:{value:(_vm.ipc.priority_date),callback:function ($$v) {_vm.$set(_vm.ipc, "priority_date", $$v)},expression:"ipc.priority_date"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"last_renewal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Last renewal")]),_vm._v(" "),_c('date-range-picker',{staticClass:"d-block",attrs:{"range":false,"clearable":true},model:{value:(_vm.ipc.last_renewal),callback:function ($$v) {_vm.$set(_vm.ipc, "last_renewal", $$v)},expression:"ipc.last_renewal"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"category"}},[_vm._v("Category")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.category),expression:"ipc.category"}],staticClass:"form-control",attrs:{"id":"category","type":"text"},domProps:{"value":(_vm.ipc.category)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "category", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"series"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"series"}},[_vm._v("Series")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.series),expression:"ipc.series"}],staticClass:"form-control",attrs:{"id":"series","type":"text"},domProps:{"value":(_vm.ipc.series)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "series", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"class"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Class(es)")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.classOptions,"multiple":true,"clearable":true,"searchable":false},model:{value:(_vm.ipc.class),callback:function ($$v) {_vm.$set(_vm.ipc, "class", $$v)},expression:"ipc.class"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"reference"}},[_vm._v("Reference")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.reference),expression:"ipc.reference"}],staticClass:"form-control",attrs:{"id":"reference","type":"text"},domProps:{"value":(_vm.ipc.reference)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "reference", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"proof_of_use"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"proof_of_use"}},[_vm._v("Proof of use")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.proof_of_use),expression:"ipc.proof_of_use"}],staticClass:"form-control",attrs:{"id":"proof_of_use","type":"text"},domProps:{"value":(_vm.ipc.proof_of_use)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "proof_of_use", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),(_vm.statusOptions)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Status")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.statusOptions,"label":"label","reduce":function (status) { return status.id; },"clearable":true,"searchable":false},model:{value:(_vm.ipc.status),callback:function ($$v) {_vm.$set(_vm.ipc, "status", $$v)},expression:"ipc.status"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"extention"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"extention"}},[_vm._v("Extention")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.extention),expression:"ipc.extention"}],staticClass:"form-control",attrs:{"id":"extention","type":"text"},domProps:{"value":(_vm.ipc.extention)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "extention", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"ctype"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Ctype")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.ctypeOptions,"clearable":true,"searchable":false},model:{value:(_vm.ipc.ctype),callback:function ($$v) {_vm.$set(_vm.ipc, "ctype", $$v)},expression:"ipc.ctype"}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"owner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"owner"}},[_vm._v("Owner")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.owner),expression:"ipc.owner"}],staticClass:"form-control",attrs:{"id":"owner","type":"text"},domProps:{"value":(_vm.ipc.owner)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "owner", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"family"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"family"}},[_vm._v("Family")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.family),expression:"ipc.family"}],staticClass:"form-control",attrs:{"id":"family","type":"text"},domProps:{"value":(_vm.ipc.family)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "family", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","name":"comments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"comments"}},[_vm._v("Comments")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipc.comments),expression:"ipc.comments"}],staticClass:"form-control",attrs:{"id":"comments"},domProps:{"value":(_vm.ipc.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ipc, "comments", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group mb-0"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("\n                    "+_vm._s(_vm.heading)+"\n                ")])])],1)]}}],null,false,2277137795)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }